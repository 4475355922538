.root {
  @apply relative inline-flex cursor-default select-none justify-center border border-transparent font-medium antialiased transition duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed;
}

.sizeDefault {
  @apply rounded-md px-4 py-[7px] text-sm;
}

.sizeSmall {
  @apply rounded-md px-3 py-[5px] text-sm;
}

.sizeLarge {
  @apply rounded-lg px-4 py-3 text-lg;
}

.plainDefault {
  @apply bg-slate-900 text-white hover:bg-slate-700 focus-visible:ring-slate-900 disabled:bg-neutral-100 disabled:text-neutral-400;
}

.plainDanger {
  @apply bg-red-600 text-white hover:bg-red-700 focus-visible:ring-red-600 disabled:bg-red-100 disabled:text-red-300;
}

.secondaryDefault {
  @apply border-neutral-200 bg-neutral-50 text-neutral-900 hover:bg-neutral-100 focus-visible:ring-neutral-400 disabled:border-neutral-200 disabled:bg-neutral-100 disabled:text-neutral-400;
}

.secondaryDanger {
  @apply border-rose-200 bg-rose-50 text-rose-600 hover:bg-rose-100 focus-visible:ring-rose-400 disabled:bg-rose-300 disabled:text-rose-400;
}

.textDefault {
  @apply text-neutral-900 hover:bg-neutral-100 focus-visible:ring-neutral-400 disabled:text-neutral-400;
}

.textDanger {
  @apply text-rose-600 hover:bg-rose-100 focus-visible:ring-rose-400 disabled:text-rose-400;
}

.linkDefault {
  @apply text-sky-500 hover:bg-neutral-100 focus-visible:underline disabled:text-neutral-400;
}

.linkDanger {
  @apply text-rose-600 hover:bg-rose-100 focus-visible:ring-rose-400 disabled:text-rose-400;
}

.disabled,
.disabled:hover {
  @apply cursor-not-allowed;
}
