.withBackground {
  @apply bg-neutral-50 focus:bg-white;
}

.withPlainBackground {
  @apply bg-white focus:bg-white !important;
}

.withBorder {
  @apply border border-neutral-200 focus-within:border-neutral-200;
}

.withRing {
  @apply ring-offset-2 focus-within:ring-2 focus-within:ring-neutral-400;
}

.withRingSmall {
  @apply ring-offset-1 focus-within:ring-2 focus-within:ring-neutral-400;
}

.root:focus {
  @apply outline-none;
}
